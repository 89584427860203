import { render, staticRenderFns } from "./Donation.vue?vue&type=template&id=138d2b72&scoped=true"
import script from "./Donation.vue?vue&type=script&lang=js"
export * from "./Donation.vue?vue&type=script&lang=js"
import style0 from "./Donation.vue?vue&type=style&index=0&id=138d2b72&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "138d2b72",
  null
  
)

export default component.exports