<template>
  <div class="donation-container">
    <div class="donation-list col-center">
      <div class="donation-list-header row-between">
        <p class="donation-list-title">{{ typeSelect == 'uid' ? '账户UID转赠' : '手机号转赠' }}</p>
        <p
          class="donation-list-next row-center"
          @click="selectTab"
        >
          {{ typeSelect == 'uid' ? '通过手机号转赠' : '通过账户UID转赠' }}
          <img src="../assets/icon/donation/next.png" />
        </p>
      </div>
      <van-field
        class="donation-list-field"
        v-model="uidOrPhopne"
        @blur="formatterBlur"
        :formatter="formatter"
        format-trigger="onBlur"
        :error-message="errorMessage"
        :label="typeSelect == 'uid' ? '接受人' : '接受人'"
        :placeholder="typeSelect == 'uid' ? '我的-个人信息中查看' : '注册手机号'"
      />
      <p
        v-if="accountStatusInfo && formatterStart"
        class="account-status-info"
        :style="!accountStatus ? 'color: #FF0244;' : 'color: #F3CDB6;'"
      >{{ accountStatusInfo }}</p>
      <div class="donation-content">
        <p class="uid-phone">转赠藏品</p>
        <!--  -->
        <div
          @click="selectDonation()"
          class="donation-info row-start"
        >
          <div
            v-if="selectionCollection.length"
            class="donation-info-img row-start"
          >
            <div
              v-for="(item,index) in selectionCollection"
              v-if="index <= 3"
              class="donation-info-img-content col-center"
              :key="index"
            >
              <img
                :src="item.image"
                style="border-radius: 4px;"
                :style="aspectRatioList[index]  ? 'width: 100%;height: auto;' : 'width: auto;height: 100%;'"
              />
            </div>

            <div class="donation-info-num  col-center">
              <p>共 {{ selectionCollection.length }} 件</p>
              <p>...</p>
            </div>
          </div>
          <p
            v-if="selectionCollection.length == 0"
            class="row-start"
            style="height: 5rem;padding-left:1.1875rem;color: #5b5b5b;"
          >请选择转赠藏品</p>
          <img
            v-if="selectionCollection.length == 0"
            class="donation-next-img"
            src="../assets/icon/next.png"
          />
        </div>
        <div class="line"></div>
        <p class="donation-acout row-start">转赠数量<span style="font-size: .875rem;font-family: lantingheiweight;font-weight: normal;color: #FFFFFF;line-height: 1.1875rem;margin-left: 1.5625rem;">{{ selectionCollection.length }}</span></p>
        <p class="donation-amount row-start">上链服务费<span style="font-size: .875rem;font-family: lantingheiweight;font-weight: 800;color: #FFFFFF;line-height: 1.1875rem;margin-left: 0.6875rem;">¥ </span><span style="font-size: 1.125rem;font-family: lantingheiweight;font-weight: 800;color: #FFFFFF;line-height: 1.1875rem;margin-left: 3px;"> {{ collectionInfo.fee *  selectionCollection.length }}</span></p>
      </div>
      <van-button
        round
        block
        type="info"
        @click="donationFrist"
      >转 赠</van-button>
      <p
        class="donation-tip"
        style="margin-top:1.6875rem;"
      >1、请仔细核对接受人信息，避免造成不必要的损失。</p>
      <p class="donation-tip">2、藏品在购买成功{{collectionInfo.buyDays}}天后或受赠{{collectionInfo.transferDays}}天后可转赠。</p>
    </div>
    <el-dialog
      title="尚未设置资产密码"
      :visible.sync="donationVisible"
      @confirm="donationVisible = false"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="donationVisible = false">取 消</el-button>
        <div
          @click="$router.push('/resetPwd')"
          class="confirm-button row-center"
        >去设置</div>
      </span>
    </el-dialog>
    <van-popup
      v-model="show"
      closeable
      position="bottom"
      style="background: rgba(21, 21, 21, 0.85);"
      :style="payStep == 0 ? 'height: 560px;' : 'height: 411px;'"
    >
      <p
        v-if="payStep == 0"
        class="donation-popup-title"
      >转赠确认</p>
      <div
        style="padding: 0px 0 18px 0;"
        v-if="payStep == 0"
      >
        <div class="donation-comfirm-info">
          <div
            style="margin-bottom: 8px;"
            class="row-between"
          >
            <p class="donation-comfirm-info-title">接收账户</p>
            <p class="donation-comfirm-info-content">{{ receiveroRealName ?  uidOrPhopne + '(' + receiveroRealName +')' : uidOrPhopne}}</p>
          </div>
          <div
            style="margin-bottom: 8px;"
            class="row-between"
          >
            <p class="donation-comfirm-info-title">转赠数量</p>
            <p class="donation-comfirm-info-content">{{ selectionCollection.length }}</p>
          </div>
          <div
            style="margin-bottom: 25px;"
            class="row-between"
          >
            <p class="donation-comfirm-info-title">上链服务费</p>
            <p class="donation-comfirm-info-content">{{collectionInfo.fee *  selectionCollection.length}}</p>
          </div>
        </div>
        <div class="donation-select-collection-item-list">
          <div
            class="donation-select-collection-item"
            v-for="(item,index) in selectionCollection"
            :key="index"
          >
            <div
              style="height: 100%;"
              class="row-start"
            >
              <div class="row-start">
                <div :style="'width: 5rem;height: 5rem;margin: 0 0.875rem 0 0;background-image: url('+ item.image +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0%;background-size: 100% auto;background-color: transparent;border-radius: 4px;'"></div>
                <div>
                  <p class="donation-select-collection-item-title">{{ item.name }}</p>
                  <p class="donation-select-collection-item-id">{{ item.showId  && item.showId != '0'? item.showId : item.uniqueCode }}</p>
                  <img
                    class="select-collection-type"
                    :src="mediaType[item.cateId] == 'img' ? require('../assets/icon/media/img.png') : mediaType[item.cateId] == 'audio' ?  require('../assets/icon/media/audio.png'): mediaType[item.cateId] == 'video' ?  require('../assets/icon/media/video.png') :  require('../assets/icon/media/3d.png')"
                    alt=""
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <van-button
          style="margin-left:5%;"
          round
          block
          type="info"
          @click="comfirmOrder"
        >确 定</van-button>
      </div>
      <div v-if="payStep == 1">
        <p class="donation-popup-title">安全验证</p>
        <!-- 密码输入框 -->
        <p class="donation-popup-tip">验证资产密码</p>
        <van-password-input
          :value="pwdValue"
          :gutter="8"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          v-model="pwdValue"
          :show="showKeyboard"
          @blur="show = false"
        />
      </div>
      <div v-if="payStep == 2">
        <p class="donation-popup-title">支付上链服务费</p>
        <p class="donation-popup-donationPay">¥ {{ collectionInfo.fee *  selectionCollection.length }}</p>
        <div
          v-if="buyType == '1' || buyType == '3'"
          :style="isWechat() || buyType == '1' ? 'margin-top:120px;': ''"
          @click="selectPay('wx')"
          class="order-detail-wx row-between"
        >
          <div class="row-center">
            <img
              class="wx-icon"
              src="../assets/icon/donation/wx.png"
            />
            <p class="wx-tip">微信支付</p>
          </div>
          <img
            :style="wxPay ? 'display: block;': 'display: none;'"
            id="wxSelect"
            class="buy-wx-select"
            src="../assets/icon/donation/select.png"
          />
          <img
            :style="wxPay ? 'display: none;': 'display: block;'"
            id="wxCancer"
            class="buy-wx-cancer"
            src="../assets/icon/donation/cancer.png"
          />
        </div>
        <div
          v-if="!isWechat() && (buyType == '2' || buyType == '3')"
          :style="buyType == '2' ? 'margin-top:120px;': ''"
          @click="selectPay('zfb')"
          class="order-detail-zfb row-between"
        >
          <div class="row-center">
            <img
              class="zfb-icon"
              src="../assets/icon/donation/zfb.png"
            />
            <p class="zfb-tip">支付宝支付</p>
          </div>
          <img
            id="zfbSelect"
            :style="this.buyType == '2' ? 'display: block;': 'display: none;'"
            class="buy-zfb-select"
            src="../assets/icon/donation/select.png"
          />
          <img
            id="zfbCancer"
            :style="this.buyType == '2' ? 'display: none;': 'display: block;'"
            class="buy-zfb-cancer"
            src="../assets/icon/donation/cancer.png"
          />
        </div>
        <van-button
          :class="buyType == '0' || (this.isWechat() && buyType == '2') ? 'no-access-donation': ''"
          style="margin-left:5%;"
          round
          block
          type="info"
          @click="getAppId"
        >立 即 支 付</van-button>
      </div>
    </van-popup>
    <el-dialog
      title="账户未实名认证"
      :visible.sync="authVisible"
      @confirm="$router.push('/certification')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="authVisible = false">取 消</el-button>
        <div
          @click="$router.push('/certification')"
          class="confirm-button row-center"
        >去认证</div>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="donationErrorVisible"
      @confirm="donationErrorVisible = false"
      class="donation-dialog"
      center
    >
      <p class="donation-error-title">{{ donationErrorTitle }}</p>
      <p class="donation-error-tip">{{ donationErrorTip }}</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="donationErrorVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import Cookies from 'js-cookie'
import { getAuthCode } from '../lib/wechat.js'
import { mapGetters } from "vuex";
import Vue from 'vue';
import md5 from 'js-md5';
import config from '../config'
import { Tab,Tabs,Field,Button,Popup,PasswordInput,NumberKeyboard } from 'vant';

Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Field);
Vue.use(Button);
Vue.use(Popup);
import { Dialog } from 'element-ui';
Vue.use(Dialog);

export default {
  data () {
    return {
      receiveroRealName: '***',
      authVisible: false,
      show: false,
      selectionCollection: [],
      donationErrorVisible: false,
      donationVisible: false,
      donationErrorTitle: '',
      donationErrorTip: '',
      payStep: 0,
      pwdValue: '',
      showKeyboard: true,
      accountStatus: true,
      accountStatusInfo: '实名认证：**平，请注意核对！',
      typeSelect: 'uid',
      uidOrPhopne: '',
      errorMessage: '',
      donationPay: '¥1.0',
      payType: 'weixin',
      aspectRatio0: false,
      aspectRatio1: false,
      aspectRatio2: false,
      aspectRatio3: false,
      aspectRatioList: [],
      formatterStart: false,
      hasAssetsPassword: true,
      uid: '',
      userNamePhone: '',
      isAuth: true,
      buyType: '3',
      mediaType: config.mediaType,
      uniqueCodeList: [],
      collectionInfo: {
        image: '',
        title: '繁星',
        id: '10040005709347',
        skuNo: '',
        uniqueCode: '',
        acout: '1',
        amount: '¥29.9',
        fee: '',
        buyDays: '',
        transferDays: ''
      },
      collectionList: []
    }
  },
  watch: {
    pwdValue () {
      if (this.pwdValue.length === 6) {
        this.testOldPwd()
      }
    }
  },
  computed: {
    ...mapGetters(["userOpenId"]),
    wxPay () {
      return this.isWechat() || this.buyType == '1' || this.buyType == '3'
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    if (localStorage.getItem('uidOrPhopneType')) {
      this.typeSelect = JSON.parse(localStorage.getItem('uidOrPhopneType')).typeSelect
      this.uidOrPhopne = JSON.parse(localStorage.getItem('uidOrPhopneType')).uidOrPhopne
    }
    if (window.location.search.split('?code=').length == 2) {
      this.getOpenid(window.location.search.split('?code=')[1].split('&state=')[0])
    } else {
      this.getMyInfo()
      if (this.$route.query.id && !localStorage.getItem('selectUniqueList')) {
        this.giftChoose(this.$route.query.id)
      } else {
        if (localStorage.getItem('selectUniqueList') && localStorage.getItem('selectUniqueList').split(',')) {
          this.uniqueCodeList = localStorage.getItem('selectUniqueList').split(',')
        }
        this.giftChooseList()
      }
    }
  },
  methods: {
    // 选择转赠藏品
    selectDonation () {
      localStorage.setItem('uidOrPhopneType',JSON.stringify({ typeSelect: this.typeSelect,uidOrPhopne: this.uidOrPhopne }))
      this.$router.push('/donationSelect')
    },
    // 根据code获取openId
    getOpenid (code) {
      api.get('/wechat/getOpenid?code=' + code)
        .then(result => {

          if (result.data.success) {
            if (this.userOpenId != result.data.data.openId) {
              this.setOpenId(result.data.data.openId)
            } else {
              this.donationSecond()
              // this.$store.commit('HIDE_APPLOADING')
            }
            this.$store.commit("SET_USEROPENID",result.data.data.openId)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 修改用户OpenId
    setOpenId (openId) {
      api.post('user/updateOpenid',{ openId: openId })
        .then(result => {

          if (result.data.success) {
            // this.$store.commit('HIDE_APPLOADING')
            this.donationSecond()
            this.$store.commit("SET_USEROPENID",result.data.data.openId)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    isWechat () {
      return (
        /micromessenger/i.test(navigator.userAgent) ||
        typeof WeixinJSBridge !== "undefined"
      );
    },
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            // 是否设置过密码
            this.uid = result.data.data.uid
            this.userNamePhone = result.data.data.phone
            this.isAuth = result.data.data.hasRealname
            this.hasAssetsPassword = result.data.data.hasAssetsPassword
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    giftChooseList () {
      api
        .post('gift/choose/list?skuType=' + (localStorage.getItem('selectCollectionTab') == 0 ? '0' : '1'))
        .then(result => {
          if (result.data.success) {
            this.collectionInfo = result.data.data
            this.collectionList = []
            result.data.data.skuVos.map(i => {
              i.uniqueVos.map(j => {
                this.collectionList.push({
                  image: i.image,
                  name: i.name,
                  skuNo: i.skuNo,
                  showId: j.showId,
                  uniqueCode: j.uniqueCode,
                  id: j.id,
                  amount: j.amount,
                })
              })
            })
            this.collectionList.map(i => {
              if (localStorage.getItem('selectUniqueList') && localStorage.getItem('selectUniqueList').split(',').filter(item => item == i.uniqueCode).length) {
                this.selectionCollection.push(i)
              }
            })
            if (this.selectionCollection.length) {
              this.selectionCollection.map((i,j) => {
                if (j <= 3) {
                  let img = new Image()
                  let url = this.selectionCollection[j].image
                  img.src = url
                  setTimeout(() => {
                    switch (j) {
                      case 0:
                        this.aspectRatio0 = (img.width / img.height) < 1 ? false : true
                        this.aspectRatioList.push(this.aspectRatio0)
                        if (this.selectionCollection.length == 1) {
                          this.$store.commit('HIDE_APPLOADING')
                        }
                        break;
                      case 1:
                        this.aspectRatio1 = (img.width / img.height) < 1 ? false : true
                        this.aspectRatioList.push(this.aspectRatio1)
                        if (this.selectionCollection.length == 2) {
                          this.$store.commit('HIDE_APPLOADING')
                        }
                        break;
                      case 2:
                        this.aspectRatio2 = (img.width / img.height) < 1 ? false : true
                        this.aspectRatioList.push(this.aspectRatio2)
                        if (this.selectionCollection.length == 3) {
                          this.$store.commit('HIDE_APPLOADING')
                        }
                        break;
                      case 3:
                        this.aspectRatio3 = (img.width / img.height) < 1 ? false : true
                        this.aspectRatioList.push(this.aspectRatio3)
                        if (this.selectionCollection.length >= 4) {
                          this.$store.commit('HIDE_APPLOADING')
                        }
                        break;
                      default:
                        this.$store.commit('HIDE_APPLOADING')
                        break;
                    }
                  },1000)
                }
              })
            } else {
              this.$store.commit('HIDE_APPLOADING')
            }

          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
            this.$store.commit('HIDE_APPLOADING')
          }
        })
    },
    // 获取转赠藏品信息
    giftChoose (id) {
      api
        .post('gift/choose/' + id + '?skuType=' + (this.$route.query.type == 1 ? '0' : '1'))
        .then(result => {
          if (result.data.success) {
            this.collectionInfo = result.data.data
            localStorage.setItem('selectUniqueList',this.collectionInfo.uniqueCode)
            localStorage.setItem('selectIdList',this.collectionInfo.id)
            this.selectionCollection.push({
              image: this.collectionInfo.image,
              name: this.collectionInfo.name,
              showId: this.collectionInfo.showId,
              uniqueCode: this.collectionInfo.uniqueCode,
            })
            let img = new Image()
            let url = this.selectionCollection[0].image
            img.src = url
            setTimeout(() => {
              this.aspectRatio = (img.width / img.height) < 1 ? false : true
              this.$store.commit('HIDE_APPLOADING')
            },1500)

          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
            this.$store.commit('HIDE_APPLOADING')
          }
        })
    },
    selectTab () {
      this.accountStatusInfo = ""
      this.accountStatus = false
      this.errorMessage = ""
      this.uidOrPhopne = ""
      this.typeSelect = this.typeSelect == 'phone' ? 'uid' : 'phone'
    },
    // 支付方式选择
    selectPay (e) {
      this.payType = e == 'zfb' ? 'alipay' : 'weixin'
      document.getElementById('zfbSelect').style.display = e == 'zfb' ? "block" : "none";
      document.getElementById('zfbCancer').style.display = e == 'zfb' ? "none" : "block";
      document.getElementById('wxSelect').style.display = e == 'zfb' ? "none" : "block";
      document.getElementById('wxCancer').style.display = e == 'zfb' ? "block" : "none";
    },
    formatterBlur () {
      this.formatterStart = true
    },
    formatter () {
      if (this.typeSelect == 'uid') {
        this.accountStatusInfo = /^\d+$/.test(this.uidOrPhopne) ? "" : "请输入正确的UID"
        this.accountStatus = false
        if (/^\d+$/.test(this.uidOrPhopne)) {
          this.giftReceiver()
        }
        return this.uidOrPhopne.replace(/^\d*$/g,this.uidOrPhopne)
      } else {
        this.accountStatusInfo = /(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}/.test(this.uidOrPhopne) ? "" : "请输入正确的手机号"
        this.accountStatus = false
        if (/(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}/.test(this.uidOrPhopne)) {
          this.giftReceiver()
        }
        return this.uidOrPhopne.replace(/(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}/g,this.uidOrPhopne)
      }
    },
    giftReceiver () {
      if (this.uidOrPhopne == (this.typeSelect == 'uid' ? this.uid : this.userNamePhone)) {
        this.$toast({
          message: '不能转赠给自己',
          icon: require('../assets/icon/toast-error.png'),
        });
      } else if (this.typeSelect == 'uid' && !/^\d*$/.test(this.uidOrPhopne)) {
        this.accountStatusInfo = /^\d*$/.test(this.uidOrPhopne) ? "" : "请输入正确的UID"
        this.accountStatus = false
      } else if (this.typeSelect == 'phone' && !/(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.uidOrPhopne)) {
        this.accountStatusInfo = /(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.uidOrPhopne) ? "" : "请输入正确的手机号"
        this.accountStatus = false
      } else {
        var param = this.typeSelect == 'uid' ?
          { type: 1,receiverUid: this.uidOrPhopne,receiverPhone: "" } :
          { type: 2,receiverUid: 0,receiverPhone: this.uidOrPhopne }
        api
          .post('gift/receiver',param)
          .then(result => {
            if (result.data.success) {
              this.accountStatus = true
              this.accountStatusInfo = result.data.data.message
              this.receiveroRealName = result.data.data.receiverName
            } else {
              this.accountStatus = false
              this.accountStatusInfo = result.data.msg

            }
          })
      }

    },
    donationFrist () {
      this.formatterStart = true
      if (!this.uidOrPhopne) {
        this.$toast({
          message: '接受人账户不能为空',
          icon: require('../assets/icon/toast-error.png'),
        });
      } else if (this.typeSelect == 'uid' && !(/^\d*$/.test(this.uidOrPhopne))) {
        this.accountStatusInfo = /^\d*$/.test(this.uidOrPhopne) ? "" : "请输入正确的UID"
        this.accountStatus = false
      } else if (this.typeSelect == 'phone' && !(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.uidOrPhopne))) {
        this.accountStatusInfo = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.uidOrPhopne) ? "" : "请输入正确的手机号"
        this.accountStatus = false
      } else if (this.uidOrPhopne == (this.typeSelect == 'uid' ? this.uid : this.userNamePhone)) {
        this.$toast({
          message: '不能转赠给自己',
          icon: require('../assets/icon/toast-error.png'),
        });
      } else if (!this.isAuth) {
        this.authVisible = true
      } else if (!this.selectionCollection.length) {
        this.$toast({
          message: '请选择转赠藏品',
          icon: require('../assets/icon/toast-error.png'),
        });
      } else if (this.collectionInfo.transferNumLimit != null && this.collectionInfo.transferNumLimit !== "" && this.selectionCollection.length > this.collectionInfo.transferNumLimit) {
        this.$toast({
          message: "单笔最大转赠上限" + this.collectionInfo.transferNumLimit + "个",
          icon: require('../assets/icon/toast-error.png'),
        });
      } else if (this.collectionInfo.transferDayLimit != null && this.collectionInfo.transferDayLimit !== "" && this.selectionCollection.length > this.collectionInfo.transferDayLimit) {
        this.$toast({
          message: "今日可转赠数量剩余" + this.collectionInfo.transferDayLimit + "个",
          icon: require('../assets/icon/toast-error.png'),
        });
      } else {
        this.pwdValue = ''
        if (this.hasAssetsPassword) {
          var param = this.typeSelect == 'uid' ?
            { type: 1,receiverUid: this.uidOrPhopne,receiverPhone: "" } :
            { type: 2,receiverUid: 0,receiverPhone: this.uidOrPhopne }
          api
            .post('gift/receiver',param)
            .then(result => {
              if (result.data.success) {
                this.accountStatus = true
                this.accountStatusInfo = result.data.data.message
                this.receiveroRealName = result.data.data.receiverName
                this.payStep = 0
                this.show = true
              } else {
                this.accountStatus = false
                this.accountStatusInfo = result.data.msg

              }
            })
        } else {
          this.donationVisible = true
        }
      }
    },
    comfirmOrder () {
      this.payStep = 1
    },
    testOldPwd () {
      api
        .post('user/verifyAssetsPassword',{ assetsPassword: md5(this.pwdValue) })
        .then(result => {
          if (result.data.success) {
            if (this.collectionInfo.fee == 0) {
              // 处理转赠手续费为0时的逻辑
              this.$store.commit('SHOW_APPLOADING')
              var param = this.typeSelect == 'uid' ?
                { type: 1,idList: localStorage.getItem('selectIdList').split(','),receiverUid: this.uidOrPhopne,receiverPhone: "",payType: this.payType,pwd: md5(this.pwdValue),skuType: (localStorage.getItem('selectCollectionTab') == 0 ? '0' : '1') } :
                { type: 2,idList: localStorage.getItem('selectIdList').split(','),receiverUid: 0,receiverPhone: this.uidOrPhopne,payType: this.payType,pwd: md5(this.pwdValue),skuType: (localStorage.getItem('selectCollectionTab') == 0 ? '0' : '1') }
              api
                .post('gift/transfer',param)
                .then(result => {
                  if (result.data.success) {
                    localStorage.removeItem('selectIdList')
                    localStorage.removeItem('selectUniqueList')
                    localStorage.setItem('donationOrderId',result.data.data.orderId)
                    api
                      .post('gift/pay',{ from: this.payType == 'alipay' ? 'h5' : this.isWechat() ? 'wechat' : 'weixinh5',payType: this.payType,uni: result.data.data.orderId })
                      .then(rem => {
                        if (rem.data.success) {
                          this.$router.replace('paySuccess?unique=' + result.data.data.orderId + '&type=donation')
                        } else {
                          localStorage.removeItem('donationPayInfo')
                          this.$toast({
                            message: result.data.msg,
                            icon: require('../assets/icon/toast-error.png'),
                          });
                          this.$store.commit('HIDE_APPLOADING')
                        }
                      })

                  } else {
                    this.$store.commit('HIDE_APPLOADING')
                    localStorage.removeItem('donationPayInfo')
                    this.show = false
                    this.donationErrorVisible = true
                    this.donationErrorTitle = result.data.status != '10007' && result.data.status != '10008' ? result.data.msg : result.data.msg.split('\n')[0]
                    this.donationErrorTip = result.data.status == '10007' || result.data.status == '10008' ? result.data.msg.split('\n')[1] : ''
                  }
                })
            } else {
              this.getPayChannel()
            }
          } else {
            this.pwdValue = ''
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    // 获取后台配置支付状态
    getPayChannel () {
      api
        .get('common/getPayChannel')
        .then(result => {
          if (result.data.success) {
            this.buyType = result.data.data.pay_channel_gift
            this.payType = this.buyType == '2' ? 'alipay' : 'weixin'
            this.payStep = 2
          } else {
            this.$store.commit('HIDE_APPLOADING')
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    getAppId () {
      localStorage.setItem('donationPayInfo',JSON.stringify({ typeSelect: this.typeSelect,uidOrPhopne: this.uidOrPhopne,pwd: md5(this.pwdValue) }))
      if (this.buyType == '0') {
        // 未开发购买渠道
      } else if (this.isWechat() && (this.buyType == '1' || this.buyType == '3')) {
        window.location.href = getAuthCode(window.location.hash)
      } else if (this.isWechat() && this.buyType == '2') {
        // 微信内使用支付宝
      } else {
        this.donationSecond()
      }
    },
    donationSecond () {
      this.$store.commit('SHOW_APPLOADING')
      var param = JSON.parse(localStorage.getItem('donationPayInfo')).typeSelect == 'uid' ?
        { type: 1,idList: localStorage.getItem('selectIdList').split(','),receiverUid: JSON.parse(localStorage.getItem('donationPayInfo')).uidOrPhopne,receiverPhone: "",payType: this.payType,pwd: JSON.parse(localStorage.getItem('donationPayInfo')).pwd,skuType: (localStorage.getItem('selectCollectionTab') == 0 ? '0' : '1') } :
        { type: 2,idList: localStorage.getItem('selectIdList').split(','),receiverUid: 0,receiverPhone: JSON.parse(localStorage.getItem('donationPayInfo')).uidOrPhopne,payType: this.payType,pwd: JSON.parse(localStorage.getItem('donationPayInfo')).pwd,skuType: (localStorage.getItem('selectCollectionTab') == 0 ? '0' : '1') }
      api
        .post('gift/transfer',param)
        .then(result => {
          if (result.data.success) {
            localStorage.removeItem('selectIdList')
            localStorage.removeItem('selectUniqueList')
            localStorage.setItem('donationOrderId',result.data.data.orderId)
            api
              .post('gift/pay',{ from: this.payType == 'alipay' ? 'h5' : this.isWechat() ? 'wechat' : 'weixinh5',payType: this.payType,uni: result.data.data.orderId })
              .then(rem => {
                if (rem.data.success) {
                  if (Cookies.get('Device') == 'pico') {
                    this.$router.replace('/share?unique=' + result.data.data.orderId + '&type=donation')
                  } else {
                    if (this.payType == 'alipay') {
                      localStorage.setItem('buyDetailReset',1)
                      const divForm = document.getElementsByTagName('divform')
                      if (divForm.length) {
                        document.body.removeChild(divForm[0])
                      }
                      const div = document.createElement('divform');
                      div.innerHTML = rem.data.data.result.jsConfig.mweb_url; // data就是接口返回的form 表单字符串
                      document.body.appendChild(div);
                      // document.forms[0].setAttribute('target','_blank') // 新开窗口跳转
                      document.forms[0].submit();
                    } else {
                      if (this.isWechat()) {
                        window.location.href = config.locationHref + 'paySuccess?unique=' + localStorage.getItem('donationOrderId') + '&type=donation'
                        function onBridgeReady () {
                          WeixinJSBridge.invoke(
                            'getBrandWCPayRequest',{
                            "appId": rem.data.data.result.jsConfig.appId,     //公众号ID，由商户传入     
                            "timeStamp": rem.data.data.result.jsConfig.timestamp,         //时间戳，自1970年以来的秒数     
                            "nonceStr": rem.data.data.result.jsConfig.nonceStr, //随机串     
                            "package": rem.data.data.result.jsConfig.package,
                            "signType": rem.data.data.result.jsConfig.signType,         //微信签名方式：     
                            "paySign": rem.data.data.result.jsConfig.paySign //微信签名 
                          },
                            function (res) {
                              if (res.err_msg == "get_brand_wcpay_request:ok") {
                                // 使用以上方式判断前端返回,微信团队郑重提示：
                                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                //  window.location.href = config.locationHref + 'paySuccess?unique=' + localStorage.getItem('donationOrderId') + '&type=donation'
                              }
                            });
                        }
                        if (typeof WeixinJSBridge == "undefined") {
                          if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady',onBridgeReady,false);
                          } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady',onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady',onBridgeReady);
                          }
                        } else {
                          onBridgeReady();
                        }
                      } else {
                        setTimeout(() => {
                          window.location.href = rem.data.data.result.jsConfig.mweb_url
                        },300);
                        window.location.href = config.locationHref + 'paySuccess?unique=' + result.data.data.orderId + '&type=donation'
                      }
                    }
                  }
                } else {
                  localStorage.removeItem('donationPayInfo')
                  this.$toast({
                    message: result.data.msg,
                    icon: require('../assets/icon/toast-error.png'),
                  });
                  this.$store.commit('HIDE_APPLOADING')
                }
              })

          } else {
            this.$store.commit('HIDE_APPLOADING')
            localStorage.removeItem('donationPayInfo')
            this.show = false
            this.donationErrorVisible = true
            this.donationErrorTitle = result.data.status != '10007' && result.data.status != '10008' ? result.data.msg : result.data.msg.split('\n')[0]
            this.donationErrorTip = result.data.status == '10007' || result.data.status == '10008' ? result.data.msg.split('\n')[1] : ''
          }
        })
    },
  },
}
</script>
<style lang="less" scoped>
.donation-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .van-button {
    width: 90%;
    height: 2.875rem;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .donation-tip {
    width: 90%;
    margin-left: 5%;
    font-size: 12px;
    font-family: lantingheiweight;
    font-weight: 200;
    color: #cccccc;
    line-height: 22px;
    letter-spacing: 1px;
  }
  .donation-list {
    width: 100%;
    margin: 0.625rem 0;
    border-radius: 5px;
    background-color: #1c1c1c;
    padding-top: 0.8125rem;

    .donation-list-header {
      width: 90%;
      margin-bottom: 1.125rem;
      .donation-list-title {
        font-size: 1.375rem;
        font-weight: 300;
        color: #ffffff;
        line-height: 1.9375rem;
        letter-spacing: 0.0625rem;
      }
      img {
        width: 1rem;
        height: auto;
        margin-left: 0.375rem;
      }
      .donation-list-next {
        font-size: 0.75rem;
        font-family: lantinghei;
        font-weight: normal;
        color: #f3cdb6;
        line-height: 1rem;
      }
    }
    .donation-list-field {
      width: 90%;
      height: 3.25rem;
      background: #313131 !important;
      border-radius: 0.25rem;
      margin-bottom: 0.75rem;
    }
    .van-cell::after {
      display: none;
    }

    .van-cell {
      background: none;
      padding: 1rem 0.8125rem;

      .van-cell__title,
      .van-cell__value {
        font-size: 0.875rem;
        font-family: lantingheiweight;
        font-weight: normal;
        color: #b3b3b3 !important;
        line-height: 1.1875rem;
      }
      /deep/.van-field__label {
        font-size: 14px;
        font-family: lantingheiweight;
        font-weight: 500;
        color: #b3b3b3;
        line-height: 19px;
      }
      /deep/.van-field__control {
        font-size: 0.875rem;
        font-family: lantingheiweight;
        color: #ffffff;
        font-weight: 500;
        line-height: 1.1875rem;
      }

      .van-field__error-message {
        position: absolute;
        top: 1rem;
      }
    }
    .account-status-info {
      width: 90%;
      margin: 0 0 0.875rem 0;
      font-size: 0.875rem;
      font-family: lantingheiweight;
      font-weight: bold;
      color: #f3cdb6;
      line-height: 1.1875rem;
    }
    .donation-content {
      width: 90%;
      height: 15.4375rem;
      background: #232323;
      border-radius: 0.25rem;
      margin-bottom: 3.0625rem;
      .uid-phone {
        width: 90%;
        margin: 0.875rem 0 0.6875rem 1.1875rem;
        font-size: 0.875rem;
        font-family: lantingheiweight;
        font-weight: normal;
        color: #b3b3b3;
        line-height: 1.1875rem;
      }
      .donation-info {
        font-size: 14px;
        font-family: lantingheiweight;
        font-weight: normal;
        color: #b3b3b3;
        line-height: 19px;
        position: relative;
        .donation-next-img {
          position: absolute;
          width: 8px;
          height: auto;
          right: 1.1875rem;
        }
        .donation-info-img {
          width: 80vw;
          margin-left: 5vw;
          height: 5rem;
          .donation-info-img-content {
            width: 14vw;
            height: 14vw;
            background: #d0d0d0;
            border-radius: 4px;
            margin-right: 2vw;
          }
          .donation-info-num {
            width: 14vw;
            height: 14vw;
            background: #363636;
            border-radius: 4px;
            p {
              font-size: 12px;
              font-family: PingFang-SC-Semibold, PingFang-SC;
              font-weight: 600;
              color: #e0e0e0;
              line-height: 12px;
            }
          }
        }
        .donation-info-title {
          font-size: 1.1875rem;
          font-family: lantingheiweight;
          font-weight: bold;
          color: #ffffff;
          line-height: 1.6875rem;
          margin-bottom: 0.4375rem;
        }
        .donation-info-id {
          width: 50vw;
          word-break: break-all;
          font-size: 0.75rem;
          font-family: lantinghei;
          font-weight: normal;
          color: #ffffff;
          line-height: 1rem;
        }
      }
      .line {
        margin: 1.125rem 0.625rem 1.625rem 0.6875rem;
        height: 1px;
        background: #333232;
      }
      .donation-acout,
      .donation-amount {
        font-size: 0.875rem;
        font-family: lantingheiweight;
        font-weight: normal;
        color: #b3b3b3;
        line-height: 1.1875rem;
        margin-left: 1.1875rem;
        margin-bottom: 1rem;
      }
    }
  }
  .van-popup {
    .donation-popup-title {
      margin: 1.625rem 0 1.25rem 0;
      text-align: center;
      font-size: 18px;
      font-family: lantingheiweight;
      font-weight: 700;
      color: #f0f0f0;
      line-height: 25px;
    }
    .donation-popup-donationPay {
      text-align: center;
      font-size: 2.1875rem;
      font-family: SFUIDisplay-Bold, SFUIDisplay;
      font-weight: bold;
      color: #ffffff;
      line-height: 2.625rem;
      margin-bottom: 2.4375rem;
    }
    .donation-popup-tip {
      margin: 0 0 0.875rem 1rem;
      font-size: 14px;
      font-family: lantingheiweight;
      font-weight: 600;
      color: #666666;
      line-height: 19px;
    }
    .van-password-input__security li {
      background: #565656;
      border-radius: 0.25rem;
    }
    .van-number-keyboard {
      background: rgba(21, 21, 21, 0.85);
    }
    /deep/.van-number-keyboard .van-key {
      background-color: #646465;
      box-shadow: 0px 1px 0px 0px #000000;
      font-family: Helvetica;
      color: #ffffff;
    }
    .order-detail-wx,
    .order-detail-zfb {
      width: 90%;
      height: 3.25rem;
      margin-left: 5%;
      background: #313131;
      border-radius: 0.25rem;
      .zfb-icon {
        width: 1.625rem;
        height: auto;
        margin: 0 2rem 0 1.3125rem;
      }
      .wx-icon {
        width: 2rem;
        height: auto;
        margin: 0 1.75rem 0 1.1875rem;
      }
      .wx-tip,
      .zfb-tip {
        font-size: 14px;
        font-family: lantingheiweight;
        font-weight: 700;
        color: #e4e4e4;
        line-height: 19px;
      }
      .buy-zfb-select,
      .buy-zfb-cancer,
      .buy-wx-select,
      .buy-wx-cancer {
        width: 1.25rem;
        height: auto;
        margin-right: 0.625rem;
      }
      .buy-zfb-cancer,
      .buy-wx-select {
        display: block;
      }
      .buy-wx-cancer,
      .buy-zfb-select {
        display: none;
      }
    }
    .order-detail-wx {
      margin-bottom: 0.9375rem;
    }
    .order-detail-zfb {
      margin-bottom: 1.5625rem;
    }
  }
  .no-access-donation {
    background-image: url('../assets/icon/open-soon.png') !important;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: cover !important;
    background-color: transparent;
    margin-top: 160px;
  }
}
.donation-select-collection-item-list {
  height: 330px;
  overflow-y: auto;
}
.donation-select-collection-item {
  height: 116px;
  width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid #3f3f3f;
  .donation-select-collection-item-img {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
    border-radius: 8px;
  }
  .donation-select-collection-item-title {
    font-size: 18px;
    font-family: lantingheiweight;
    font-weight: bold;
    color: #ffffff;
    line-height: 25px;
  }
  .donation-select-collection-item-id {
    font-size: 12px;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
    margin: 8px 0;
    word-break: break-all;
    width: 60vw;
  }
  .select-collection-type {
    width: 14px;
    height: auto;
  }
}
.donation-select-collection-item-selectimg {
  width: 20px;
  height: 20px;
}
.donation-comfirm-info {
  width: 90vw;
  margin-left: 5vw;
  border-bottom: 1px solid #3f3f3f;
  .donation-comfirm-info-title {
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: 300;
    color: #b3b3b3;
    line-height: 19px;
  }
  .donation-comfirm-info-content {
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: 800;
    color: #b3b3b3;
    line-height: 19px;
  }
}
</style>